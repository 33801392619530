import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps } from "react-router-dom";
import { withRouter, GLForm, FormItemsLayout, FormItemInModalParams, FormHelper, GLFormComponentProps, GLGlobal, GLUtil, RoleName, GLLocale, MessageHelper, NotificationType, ServerExceptionCode, validateLoginId, LoginIdType } from "gl-commonui";
import { Form, Row, Col, Button, Input, Spin } from "antd-min";
import { GSAdminLocale } from '../../../locales/localeid';
import { lazyInject, TYPES, inviteEmailValidation, fmtMsg, GLRegistrationLocalizationHelper, InvitationChannel } from '../../../util/index';
import { AdminPathConfig as PathConfig } from "../../../config/pathconfig";
import { IInvitationService, InvitationResponse } from '../../../service/admin/acceptinvitation/index';
import { IAccountService, RedeemProps } from '../../../service/admin/accountservice/index';
import { GLLoginIdInput } from '@app/components/register/gl-login-input';
import FormItem from 'antd/lib/form/FormItem';
import { GLRegistrationLocalization } from '@app/components/register/Model';
import { Result } from '@app/components/result/result';
import "@app/components/register/index.less";
import "./redeem.less";

interface RedeemExistingProps extends RouteComponentProps<any> { }
interface RedeemExistingStates {
    disabled?: boolean
    loading: boolean;
}

@withRouter
@GLForm.create()
export class RedeemExistingPage extends Component<RedeemExistingProps & GLFormComponentProps, RedeemExistingStates> {
    @lazyInject(TYPES.IAccountService)
    accountservice: IAccountService
    @lazyInject(TYPES.IInvitationService)
    service: IInvitationService
    code = GLUtil.queryParse().code
    codeId
    codeInfo: Partial<InvitationResponse> = {}
    invitationType
    loginId
    error = false;
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            loading: true,
        };
    }

    componentWillReceiveProps(nextProps: any, nextContext: any) {
        const { setFieldsValue } = this.props.form;
        nextProps.location !== this.props.location && setFieldsValue({ invitationCode: GLUtil.queryParse().code });
    }

    componentWillMount() {
        this.service.getInvitationInfoByCode({ invitationCode: this.code })
            .then(data => {
                this.codeInfo = data;
                this.codeId = data.id;
                this.invitationType = data.invitationType;
                this.setState({ loading: false });
            }).catch(resp => { 
                this.error = true;
                this.setState({ loading: false });
            });
    }

    redeem(user, invitationChannel: InvitationChannel) {
        const params = {
            email: user.email,
            password: user.password,
            invitationCodeType: this.invitationType,
            invitationCodeId: this.codeId,
            invitationChannel
        };
        this.accountservice.redeemUser(params).then(() => {
            this.props.history.push({ pathname: PathConfig.AcceptInvitationSuccess, search: GLUtil.queryStringify({ email: user.email, code: this.code }) });
        }).catch(resp => {
            this.setState({ disabled: false });
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ disabled: true });
                const email = values.email.type === LoginIdType.Email ? values.email.email : `${values.email.countryCode}${values.email.phone}`;
                const channel = values.email.type === LoginIdType.Email ? InvitationChannel.Email : InvitationChannel.Phone;
                this.redeem({...values, email}, channel);
            }
        });
    }

    renderLoginIdFormItem = (emailParams: FormItemInModalParams) => {
        const { getFieldDecorator } = this.props.form;
        const registrationLocalization: GLRegistrationLocalization = GLRegistrationLocalizationHelper.getGLRegistrationLocalization(
            this.codeInfo.enablePhoneRegistration
        );
        return (
            <FormItem
                label={fmtMsg({
                    id: this.codeInfo.enablePhoneRegistration
                        ? GSAdminLocale.RegistrationFormEmailPhone
                        : GSAdminLocale.RegistrationFormEmail
                })}
                required
            >
                {getFieldDecorator(emailParams.fieldName, {
                    initialValue: {
                        type: LoginIdType.Email,
                        country: "US",
                        countryCode: "+1",
                        phone: "",
                        email: ""
                    },
                    rules: [
                        {
                            validator: validateLoginId(registrationLocalization)
                        },
                        {
                            required: true,
                            message: fmtMsg({
                                id: this.codeInfo.enablePhoneRegistration
                                    ? GSAdminLocale.RegistrationFormEmailPhoneRequired
                                    : GSAdminLocale.RegistrationFormEmailReq
                            })
                        }
                    ]
                })(
                    <GLLoginIdInput
                        onChange={value => (this.loginId = value)}
                        dualMode={this.codeInfo.enablePhoneRegistration}
                        disabled={false}
                        placeholder={" "}
                    />
                )}
            </FormItem>
        );
    };

    renderError = () => {
       return <Result
            type="warning"
            textId={GSAdminLocale.RegistrationRedeemErrTitle}
            extra={
            <Button type="primary" key="goBack" onClick={() => this.props.history.goBack()}>
                {fmtMsg({ id: GSAdminLocale.RegistrationRedeemErrDesc })}
            </Button>
        }/>
    }

    render() {
        const form = this.props.form;
        const renderFormItem = FormHelper.renderFormItem;
        const emailParams: FormItemInModalParams = {
            form: form,
            localeId: GSAdminLocale.RedeemExistingEmail,
            fieldName: RedeemProps.email,
            formControl: <Input autoComplete="new-email" />,
            required: true,
            rules: [
                {
                    validator: (
                        rule: any,
                        value: string,
                        callback: (msg?: string) => void
                    ) => {
                        inviteEmailValidation(value, callback);

                        callback();
                    },
                },
            ],
        };

        const passwordParams: FormItemInModalParams = {
            form: form,
            localeId: GSAdminLocale.RedeemExistingPassword,
            fieldName: RedeemProps.password,
            formControl: <Input type="password" autoComplete="new-password" />,
            required: true,
        }

        return <div className="redeem-container">
            <Row className='registerHeader'>{GLGlobal.intl.formatMessage({ id: GSAdminLocale.RedeemExistingTitle })}</Row>
            <Row className='register'>
                {this.state.loading 
                    ? <Spin spinning={this.state.loading}/>
                    : this.error 
                        ? this.renderError()
                        : <GLForm form={form} onSubmit={this.handleSubmit.bind(this)}>
                        <FormItemsLayout>
                            {this.renderLoginIdFormItem(emailParams)}
                            {renderFormItem(passwordParams)}
                            <Form.Item>
                                <div className='gl-center-h'>
                                    <Button className="invitationButton" disabled={this.state.disabled} type="primary" htmlType="submit">
                                        {GLGlobal.intl.formatMessage({ id: GSAdminLocale.RedeemBtn })}
                                    </Button>
                                </div>
                            </Form.Item>
                        </FormItemsLayout>
                      </GLForm>
                }
            </Row>
        </div>
    }
}
